import React from 'react'
import LibraryWrapper from './LibraryWrapper'
import { Section, Typography, Icon } from '../../components/library'
import theme from '../../theme'

function section() {
    return (
        <LibraryWrapper>

            <Section 
                footer={<Typography variant="caption">You can earn 2x <Icon name="bone" bg={theme.palette.purple.main} fg="#fff" /></Typography>}
            >
                <Typography variant="body1" align="left">
                    Your IMPACT wearable tracks the number of clicks you press when symptoms occur. Pair it with the app now and sync data daily.
                </Typography>
            </Section>
            <hr />
            <Section 
                title="DAILY CHECK-IN" 
                editTo="/components"
                footer={<Typography variant="caption">Check in at the end of the week to earn <Icon name="bone" bg={theme.palette.purple.main} fg="#fff" /></Typography>}
            >
                <Typography variant="body1" align="left">
                    Check in with parent about asthma symptoms 5 times this week.
                </Typography>
            </Section>

        </LibraryWrapper>
    )
}
export default section
